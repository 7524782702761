import React, { useEffect, useState, useCallback } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { StepIndicator } from "@/components/step-indicator";
import { AvatarGroup } from "@/components/avatar-group";
import { CustomInput } from "./custom-input";
import { ProfileCard, ProfileCardSkeleton } from "./profile-card";
import { useSnapshot } from "valtio";
import state, { Steps } from "@/store/state";
import actions from "@/store/actions";
import { CustomAlert } from "./custom-alert";
import { useSearchParams, useLocation } from "react-router-dom";
import { trackAccountSelection } from "@/utils/analytics";
import { motion } from "framer-motion";
import { Dialog, DialogContent } from "./ui/dialog";
import { cn } from "@/lib/utils";

interface SelectAccountProps {
  onContinue: () => void;
}

interface TimelineStep {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const timelineSteps: TimelineStep[] = [
  {
    title: "Enter Your Username",
    description: "Just type your TikTok username - no password needed!",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M19.2101 15.74L15.67 19.2801C15.53 19.4201 15.4 19.68 15.37 19.87L15.18 21.22C15.11 21.71 15.45 22.05 15.94 21.98L17.29 21.79C17.48 21.76 17.75 21.63 17.88 21.49L21.42 17.95C22.03 17.34 22.32 16.63 21.42 15.73C20.53 14.84 19.8201 15.13 19.2101 15.74Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.7002 16.25C19.0002 17.33 19.8402 18.17 20.9202 18.47" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.41016 22C3.41016 18.13 7.26018 15 12.0002 15C13.0402 15 14.0402 15.15 14.9702 15.43" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  {
    title: "Watch Your Growth",
    description: "See real engagement from active users within minutes! 🚀",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 22H22" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
];

export function SelectAccount({ onContinue }: SelectAccountProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [showUsernameHelp, setShowUsernameHelp] = useState(false);
  const [hasSpaces, setHasSpaces] = useState(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 1500);
  const { user, isLoading, error } = useSnapshot(state);
  const [searchParams] = useSearchParams();
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [showTimeline, setShowTimeline] = useState(false);
  const location = useLocation();
  const isFromFreePage = location.search.includes('from=get-free');

  useEffect(() => {
    actions.initializeData();
    const usernameParam = searchParams.get("username");
    if (usernameParam) {
      const cleanedUsername = cleanSearchQuery(usernameParam);
      actions.resetAllState();
      setSearchQuery(cleanedUsername);
      searchUser(cleanedUsername);
    }
    setIsInitialSearch(false);
  }, []);

  useEffect(() => {
    if (isFromFreePage) {
      setShowTimeline(true);
    }
  }, [isFromFreePage]);

  const cleanSearchQuery = (query: string): string => {
    return query
      .replace(/https:\/\/(www\.)?tiktok\.com\//g, "")
      .replace(/@/g, "")
      .split("?")[0]
      .trim();
  };

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | string) => {
      const newValue = typeof event === "string" ? event : event.target.value;
      const hasSpacesInValue = newValue.includes(" ");
      setHasSpaces(hasSpacesInValue);
      
      if (hasSpacesInValue) {
        actions.resetUser();
        setIsProfileSelected(false);
      }
      
      if (!hasSpacesInValue) {
        const cleaned = cleanSearchQuery(newValue);
        setSearchQuery(cleaned);
      }
    },
    []
  );

  const handleClear = useCallback(() => {
    setSearchQuery("");
    setIsProfileSelected(false);
    actions.resetAllState();
  }, []);

  useEffect(() => {
    if (!isInitialSearch && debouncedSearchQuery.length > 2 && !hasSpaces) {
      setIsProfileSelected(false);
      searchUser(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, isInitialSearch, hasSpaces]);

  const searchUser = async (query: string) => {
    setIsProfileSelected(false);
    setRetryCount(0);

    try {
      await actions.searchTikTokUser(query);
      setIsProfileSelected(true);
      trackAccountSelection();

    } catch (error) {
      console.error("Error searching user:", error);
      setIsProfileSelected(false);
      actions.resetUser();
      if (error instanceof Error && error.message.includes("Retrying")) {
        setRetryCount((prev) => prev + 1);
        if (retryCount < 5) {
          setTimeout(() => searchUser(query), 500);
        }
      }
    }
  };

  const handleProfileSelect = useCallback((selected: boolean) => {
    setIsProfileSelected(selected);
  }, []);

  const isPlatformiOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  useEffect(() => {
    if (error && isPlatformiOS()) {
      const input = document.querySelector('input[name="tiktok-username"]');
      if (input) {
        (input as HTMLInputElement).focus();
        (input as HTMLInputElement).click();
      }
    }
  }, [error]);

  const placeholderVariants = {
    normal: { scale: 1 },
    attention: { scale: [1, 1.1, 1], transition: { repeat: 2, duration: 0.7 } },
  };

  const handleContinue = () => {
    actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
    onContinue();
  };

  return (
    <>
      <Dialog open={showTimeline} onOpenChange={setShowTimeline}>
        <DialogContent className="max-w-[327px] rounded-[12px]">
          <div className="p-0">
            <div className="text-center mb-5">
              <h2 className="text-[22px] font-semibold text-gray-900">How It Works</h2>
              <p className="text-[13px] text-gray-500 mt-1">Get started in less than 60 seconds!</p>
            </div>
            <div className="space-y-2">
              {timelineSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    delay: index * 0.3,
                    duration: 0.5,
                    ease: "easeOut"
                  }}
                  className="flex gap-3"
                >
                  <div className="flex flex-col items-center">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        delay: index * 0.3 + 0.2,
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                      }}
                      className={cn(
                        "w-6 h-6 rounded-full flex items-center justify-center",
                        "bg-purple-300 text-white"
                      )}
                    >
                      <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {step.icon}
                      </svg>
                    </motion.div>
                    {index !== timelineSteps.length - 1 && (
                      <motion.div 
                        initial={{ scaleY: 0 }}
                        animate={{ scaleY: 1 }}
                        transition={{
                          delay: index * 0.3 + 0.4,
                          duration: 0.5,
                          ease: "easeOut"
                        }}
                        className="w-[2px] h-14 bg-purple-100 origin-top mt-2"
                      />
                    )}
                  </div>
                  <div className="flex-1 pb-3">
                    <h3 className="text-[14px] font-semibold text-gray-900">{step.title}</h3>
                    <p className="text-[13px] text-gray-500 mt-0.5">{step.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
            <Button
              onClick={() => setShowTimeline(false)}
              className="w-full mt-5 !font-semibold"
            >
              Continue
            </Button>
            <p className="text-[12px] text-gray-400 text-center mt-3">🔒 Safe & Secure • No Password Required</p>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={showUsernameHelp} onOpenChange={setShowUsernameHelp}>
        <DialogContent className="max-w-[327px] rounded-[12px]">
          <div className="p-0">
            <h2 className="text-[22px] font-semibold text-gray-900 mb-4">How to Find Your TikTok Username</h2>
            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                  <span className="text-purple-600 text-sm">1</span>
                </div>
                <p className="text-gray-600 text-sm">Open your TikTok profile</p>
              </div>
              <div className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                  <span className="text-purple-600 text-sm">2</span>
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 text-sm">Your username appears at the top of your profile with an @ symbol</p>
                  <img 
                    src="/profile.png" 
                    alt="TikTok Profile Username Location" 
                    className="mt-2 rounded-lg w-full object-cover border border-gray-100"
                  />
                </div>
              </div>
              <div className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                  <span className="text-purple-600 text-sm">3</span>
                </div>
                <p className="text-gray-600 text-sm">Enter your username without the @ symbol</p>
              </div>
            </div>
            <Button
              onClick={() => setShowUsernameHelp(false)}
              className="w-full mt-6"
            >
              Got it
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="p-6 flex flex-col items-center gap-4 bg-gray-50 border-t border-b border-gray-100 h-full overflow-y-auto z-10">
        <div className="flex justify-center w-full">
          <Card
            className="p-5 rounded-2xl border border-[#F4F4F5] relative bg-white shadow-[0px_1px_2px_0px_rgba(228,229,231,0.24)] w-[420px] min-h-[280px] z-10"
            style={{
              backgroundImage: "url(/frame1.png), url(/frame2.png)",
              backgroundPosition:
                "top -50px right -100px, bottom -90px left -70px",
              backgroundRepeat: "no-repeat, no-repeat",
            }}
          >
            <div className="flex justify-center mb-4">
              <img src="/logo.png" alt="Famepeak" className="w-9 h-9" />
            </div>
            <h2 className="text-center mb-2">
              <span className="text-purple-300 text-body-xl-m">Grow Your</span>{" "}
              <span className="text-gray-900 text-body-xl-r">
                TikTok Account
              </span>
            </h2>
            <p className="text-center text-gray-400 text-body-xs-r mb-4">
              Join thousands of satisfied creators and <br />
              experience rapid growth with us.
            </p>
            <div className="flex justify-center mb-5">
              <AvatarGroup />
            </div>
            <div className="relative">
              <motion.div
                animate={{ scale: [1, 1.05, 1] }}
                transition={{ repeat: 3, duration: 0.7 }}
              >
                <CustomInput
                  placeholder="type your tiktok username"
                  value={searchQuery}
                  onChange={handleSearch}
                  onClear={handleClear}
                  loading={isLoading}
                  error={!!error || hasSpaces}
                  name="tiktok-username"
                />
                {hasSpaces && (
                  <div 
                    className="mt-2 text-sm text-red-300 cursor-pointer underline hover:underline flex items-center gap-1"
                    onClick={() => setShowUsernameHelp(true)}
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-red-300">
                      <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12 8V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M11.9945 16H12.0035" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    How can I find my username?
                  </div>
                )}
              </motion.div>
            </div>
          </Card>
        </div>

        {error && (
          <CustomAlert variant="red" title="Oops!" description={error} />
        )}
        {!isLoading && !error && user && (
          <ProfileCard
            username={user.username}
            name={user.name}
            followers={user.totalFollower.toString()}
            likes={user.totalLike.toString()}
            avatarUrl={user.profilePicture}
            isVerified={true}
            isSelected={true}
            onSelect={handleProfileSelect}
          />
        )}
        {isLoading && <ProfileCardSkeleton />}

        {/* Guarantee section - always visible and full width */}
        <div className="mt-1 w-full">
          <div className="bg-white rounded-lg shadow-sm p-4 flex items-start gap-4">
            <motion.div
              animate={{ scale: [1, 1.05, 1] }}
              transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
              className="flex-shrink-0"
            >
              <img
                src="https://dyo1uzh2defwk.cloudfront.net/img/guarantee.webp"
                alt="Guarantee"
                className="w-20 h-20 sm:w-22 sm:h-22 md:w-24 md:h-24 lg:w-20 lg:h-20 object-contain"
              />
            </motion.div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                10-day money-back guarantee
              </h3>
              <p className="text-sm text-gray-600">
                If you're not seeing significant follower and engagement growth
                within 10 days, we'll give you a full refund, no questions
                asked.
              </p>
            </div>
          </div>
        </div>
      </div>
      {user && (
        <div className="py-3 px-4 fixed bottom-0 left-0 right-0 max-w-2xl bg-white mx-auto">
          <Button
            onClick={handleContinue}
            disabled={isLoading || !!error}
            className="w-full"
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
}
