import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { actions } from "@/store/actions";
import type { TikTokVideoResponse } from "@/store/actions";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export function TiktokDownloadPage() {
  const [tiktokUrl, setTiktokUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setTiktokUrl(text);
    } catch (err) {
      console.error("Failed to read clipboard:", err);
      toast.error("Failed to read from clipboard");
    }
  };

  const handleDownload = async () => {
    if (!tiktokUrl) {
      toast.error("Please enter a TikTok video URL");
      return;
    }

    setIsLoading(true);
    try {
      const data = await actions.downloadTikTok(tiktokUrl);
      
      if (data.downloadUrl) {
        const link = document.createElement('a');
        link.href = data.downloadUrl;
        link.download = `tiktok-${data.id}.mp4`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        toast.success("Download started!");
      } else {
        throw new Error('Download URL not found');
      }
    } catch (error) {
      console.error('Download error:', error);
      toast.error("Failed to download video. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>TikTok Video Downloader Without Watermark - Famepeak</title>
        <meta name="description" content="Download TikTok videos without watermark for free. High quality, fast, and works on all devices. Best TikTok video downloader for mobile and desktop." />
        <meta name="keywords" content="tiktok downloader, tiktok video download, download tiktok without watermark, tiktok mp4 download, tiktok video saver" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="TikTok Video Downloader Without Watermark - Famepeak" />
        <meta property="og:description" content="Download TikTok videos without watermark for free. High quality, fast, and works on all devices." />
        <meta property="og:site_name" content="Famepeak" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="TikTok Video Downloader Without Watermark - Famepeak" />
        <meta name="twitter:description" content="Download TikTok videos without watermark for free. High quality, fast, and works on all devices." />
        
        {/* Additional SEO tags */}
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://famepeak.com/tiktok-downloader" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Famepeak TikTok Downloader",
            "applicationCategory": "DownloadApplication",
            "operatingSystem": "Any",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "description": "Download TikTok videos without watermark for free. High quality, fast, and works on all devices."
          })}
        </script>
      </Helmet>

      <main className="min-h-screen flex flex-col">
        <section className="bg-[#1565C0] min-h-[60vh] flex flex-col items-center justify-center px-4 py-16" aria-label="download-section">
          <div className="w-full max-w-3xl space-y-4 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-2">
              Download TikTok videos Without Watermark for FREE
            </h1>
            <p className="text-xl text-white/90 mb-8">
              Without Watermark. Fast. All devices
            </p>

            <form onSubmit={(e) => { e.preventDefault(); handleDownload(); }} className="flex flex-col md:flex-row gap-2 w-full max-w-2xl mx-auto">
              <div className="flex-1 relative">
                <Input
                  type="url"
                  placeholder="Paste TikTok link here"
                  value={tiktokUrl}
                  onChange={(e) => setTiktokUrl(e.target.value)}
                  className="w-full h-12 pl-4 pr-24 rounded-lg text-gray-900 bg-white"
                  disabled={isLoading}
                  aria-label="TikTok video URL input"
                />
                <Button
                  type="button"
                  onClick={handlePaste}
                  className="absolute right-1 top-1 h-10 px-4 bg-transparent hover:bg-gray-100 text-gray-600 font-medium rounded-md border-0"
                  disabled={isLoading}
                  aria-label="Paste URL"
                >
                  <span className="flex items-center gap-1 text-white">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Paste
                  </span>
                </Button>
              </div>
              <Button
                type="submit"
                className="h-12 px-8 bg-green-500 hover:bg-green-600 text-white font-medium"
                disabled={isLoading}
                aria-label="Download video"
              >
                {isLoading ? (
                  "Downloading..."
                ) : (
                  <span className="flex items-center gap-2">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.1222 15.4361L12.1222 3.3951" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M15.0382 12.5084L12.1222 15.4364L9.20621 12.5084" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M16.755 8.12799H17.688C19.723 8.12799 21.372 9.77699 21.372 11.813V16.697C21.372 18.727 19.727 20.372 17.697 20.372L6.55701 20.372C4.52201 20.372 2.87201 18.722 2.87201 16.687V11.802C2.87201 9.77299 4.51801 8.12799 6.54701 8.12799L7.48901 8.12799" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Download
                  </span>
                )}
              </Button>
            </form>
          </div>
        </section>

        <section className="bg-white py-16 px-4" aria-label="features-section">
          <div className="max-w-3xl mx-auto">
            <article className="text-gray-700 mb-8 text-lg">
              <p>
                Famepeak is one of the best TikTok Downloader available online to Download video tiktok without a watermark. You are not required to install any software on your computer or mobile phone, all that you need is a TikTok video link, and all the processing is done on our side so you can be one click away from downloading videos to your devices.
              </p>
            </article>

            <section aria-label="key-features">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Key features:</h2>
              <ul className="space-y-4 text-gray-700" role="list">
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  No watermark for better quality, which most of the tools out there can't.
                </li>
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  Download TikTok videos, Musically videos on any devices that you want: mobile, PC, or tablet. TikTok only allows users to download videos by its application and downloaded videos contain the watermark.
                </li>
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  Download by using your browsers: I want to keep things simple for you. No need to download or install any software. I make an application for this purpose as well but you can only install whenever you like.
                </li>
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  It's always free. I only place some ads, which support maintaining our services, and further development.
                </li>
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  New Famepeak provides users with the ability to download Tiktok's photo slide show as Mp4 Video format. The images and music in the Tiktok slide show will be automatically merged by Famepeak. In addition, you can also download each image in the slide show to your computer right away.
                </li>
              </ul>
            </section>

            <section className="mt-12" aria-label="faq-section">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Frequently Asked Questions</h2>
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger>How to get the TikTok video download link?</AccordionTrigger>
                  <AccordionContent>
                    Copy the link of the TikTok video you want to download, paste it in the input field above, and click the Download button. Famepeak will process the video and provide you with a download link.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-2">
                  <AccordionTrigger>Where are TikTok videos saved after being downloaded?</AccordionTrigger>
                  <AccordionContent>
                    When you're downloading files, they are usually saved into whatever folder you have set as your default. Your browser normally sets this folder for you. In browser settings, you can change and choose manually the destination folder for your downloaded TikTok videos.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-3">
                  <AccordionTrigger>Does Famepeak store downloaded videos or keep a copy of videos?</AccordionTrigger>
                  <AccordionContent>
                    No, Famepeak does not store or keep copies of downloaded videos. We only process the video for download and immediately remove it from our servers after the download is complete.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-4">
                  <AccordionTrigger>Do I need to install instructions or extensions?</AccordionTrigger>
                  <AccordionContent>
                    No, you don't need to install any software or browser extensions. Famepeak works directly in your web browser without any additional installations.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-5">
                  <AccordionTrigger>Do I have to pay to Tiktok Downloader without watermark?</AccordionTrigger>
                  <AccordionContent>
                    No, Famepeak's TikTok video downloader is completely free to use. You can download videos without watermark without any payment.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-6">
                  <AccordionTrigger>Can I use this Tiktok video downloader on my Android phone?</AccordionTrigger>
                  <AccordionContent>
                    Yes, Famepeak works on all devices including Android phones. Simply open your browser, visit our website, and download TikTok videos without watermark.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-7">
                  <AccordionTrigger>How do I save tiktok video /tiktok-downloader my favorite Tik Tok mp4 videos to my iPhone (IOS)?</AccordionTrigger>
                  <AccordionContent>
                    The process is the same on iPhone. Copy the TikTok video link, paste it on Famepeak, and download. The video will be saved to your iPhone's default download location or Photos app.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-8">
                  <AccordionTrigger>Is there a limit to download Tiktok videos at Famepeak?</AccordionTrigger>
                  <AccordionContent>
                    There is no strict limit on how many videos you can download. However, we recommend reasonable use of our service to ensure the best experience for all users.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-9">
                  <AccordionTrigger>Does Famepeak support downloading multiple videos / Download all videos from a certain tiktok / Htags channel?</AccordionTrigger>
                  <AccordionContent>
                    Currently, Famepeak supports downloading one video at a time. Bulk download features may be added in future updates.
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-10">
                  <AccordionTrigger>Can I download high resolution TikTok videos at Famepeak?</AccordionTrigger>
                  <AccordionContent>
                    Yes, Famepeak always tries to download the highest quality version of the video available, ensuring you get the best possible resolution.
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </section>
          </div>
        </section>
      </main>
    </>
  );
} 