import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSnapshot } from "valtio"
import state from "@/store/state"
import actions from "@/store/actions"
import { motion, AnimatePresence } from "framer-motion"

function SingleDigit({ digit, direction }: { digit: string; direction: number }) {
  return (
    <motion.span
      initial={{ y: direction * 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: direction * -20, opacity: 0 }}
      transition={{ duration: 0.35, ease: "easeInOut" }}
    >
      {digit}
    </motion.span>
  )
}

function AnimatedNumber({ value, previousValue }: { value: number; previousValue: number | null }) {
  const valueString = value.toLocaleString()
  const previousString = previousValue?.toLocaleString() || valueString

  return (
    <div className="flex text-3xl font-bold text-gray-900">
      {valueString.split("").map((digit, index) => {
        const previousDigit = previousString[index] || digit
        const direction = previousDigit ? (parseInt(digit) > parseInt(previousDigit) ? 1 : -1) : 0

        return (
          <AnimatePresence mode="wait" key={`${index}-${digit}`}>
            <SingleDigit digit={digit} direction={direction} />
          </AnimatePresence>
        )
      })}
    </div>
  )
}

export function TiktokCounterEmbed() {
  const { username } = useParams()
  const store = useSnapshot(state)
  const [followerCount, setFollowerCount] = useState<number | null>(null)
  const [previousCount, setPreviousCount] = useState<number | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchUser = async () => {
      if (!username) return

      try {
        setError(null)
        await actions.searchTikTokUser(username)
        const initialCount = store.user?.totalFollower || 0
        setFollowerCount(initialCount)
        setPreviousCount(initialCount)
      } catch (err) {
        setError("User not found")
      }
    }

    fetchUser()
  }, [username])

  useEffect(() => {
    if (!username || error) return

    const intervalId = setInterval(async () => {
      try {
        await actions.searchTikTokUser(username)
        const newCount = store.user?.totalFollower || 0
        
        if (newCount !== followerCount) {
          setPreviousCount(followerCount)
          setFollowerCount(newCount)
        }
      } catch (err) {
        console.error("Error updating follower count:", err)
      }
    }, 5000)

    return () => clearInterval(intervalId)
  }, [username, followerCount, error])

  if (error || !store.user) {
    return (
      <div className="flex h-full items-center justify-center p-4 text-center">
        <p className="text-sm text-gray-500">User not found</p>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col items-center justify-center bg-white p-4">
      <div className="flex items-center gap-3 mb-2">
        <img
          src={store.user.profilePicture || "/default-avatar.png"}
          alt={store.user.username}
          className="h-12 w-12 rounded-full"
        />
        <div className="text-left">
          <div className="font-medium">@{store.user.username}</div>
          <div className="text-sm text-gray-500">TikTok Followers</div>
        </div>
      </div>
      <div className="relative flex justify-center">
        <AnimatePresence mode="wait">
          {followerCount !== null && (
            <AnimatedNumber 
              value={followerCount} 
              previousValue={previousCount}
            />
          )}
        </AnimatePresence>
      </div>
      <div className="mt-1 text-xs text-gray-400">
        Live Counter by FamePeak
      </div>
    </div>
  )
} 