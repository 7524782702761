import { Cart, Services } from "@/store/state";
import { priceList } from "@/config";

export const DISCOUNT_PERCENTAGE = 0.25;
export function calculateSpecialOffers(cart: Readonly<Cart>) {
  const offers: Array<{
    service: Services;
    amount: number;
    price: number;
    originalPrice: number;
    serviceId: string;
  }> = [];

  cart.forEach((userCart) => {
    Object.entries(userCart.products).forEach(([service, products]) => {
      products.forEach((product) => {
        // Calculate total amount for this service
        let totalAmount = 0;

        if ("count" in product) {
          totalAmount = product.amount * product.count;
        } else {
          totalAmount = product.amount * product.posts.length;
        }

        // Find the closest package that is approximately 50% of the current amount
        const targetAmount = Math.floor(totalAmount * 0.5);
        const servicePriceList = priceList[service as Services];

        let closestPack = servicePriceList[0];
        let minDiff = Math.abs(closestPack.amount - targetAmount);

        for (const pack of servicePriceList) {
          const diff = Math.abs(pack.amount - targetAmount);
          if (diff < minDiff) {
            minDiff = diff;
            closestPack = pack;
          }
        }

        // Add to offers if not already present
        const existingOffer = offers.find(
          (offer) =>
            offer.service === service && offer.amount === closestPack.amount
        );

        if (!existingOffer) {
          offers.push({
            service: service as Services,
            amount: closestPack.amount,
            price: Number(
              (closestPack.price * (1 - DISCOUNT_PERCENTAGE)).toFixed(2)
            ),
            originalPrice: closestPack.price,
            serviceId: product.service_id,
          });
        }
      });
    });
  });

  return offers;
}
