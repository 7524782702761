import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ArrowRight, Search, Info } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import actions from "@/store/actions";
import React from "react";
import { Separator } from "@/components/ui/separator";
import { Footer } from "@/components/footer";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface ToolCardProps {
  icon: React.ReactNode;
  title: string;
  isHot?: boolean;
  onClick?: () => void;
}

function ToolCard({ icon, title, isHot, onClick }: ToolCardProps) {
  return (
    <div
      className="group relative overflow-hidden rounded-xl border border-gray-100 bg-white p-6 transition-all duration-300 hover:border-[rgb(33,150,243)] hover:shadow-lg hover:shadow-[rgb(33,150,243)]/10"
    >
      <div className="relative z-10 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex h-14 w-14 items-center justify-center rounded-xl bg-[rgba(33,150,243,0.1)] transition-transform duration-300 group-hover:scale-110">
            {icon}
          </div>
          <div>
            <span className="block text-lg font-semibold text-gray-900">{title}</span>
            <span className="mt-1 block text-sm text-gray-500">Free Forever</span>
          </div>
          {isHot && (
            <div className="flex items-center gap-1 rounded-full bg-red-50 px-3 py-1.5 transition-transform duration-300 group-hover:scale-105">
              <span className="text-sm font-medium text-red-500">Hot</span>
              <span className="text-red-500">🔥</span>
            </div>
          )}
        </div>
        <ArrowRight className="h-6 w-6 text-gray-400 transition-all duration-300 group-hover:translate-x-1 group-hover:text-[rgb(33,150,243)]" />
      </div>
      <div className="absolute inset-0 z-0 bg-gradient-to-br from-[rgb(33,150,243)]/5 to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
    </div>
  );
}

const EXAMPLE_USERS = [
  { username: "mrbeast", displayName: "@mrbeast" },
  { username: "shakira", displayName: "@shakira" },
  { username: "kyliecantrall", displayName: "@kyliecantrall" },
] as const;

export function TiktokCounterPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [showUsernameHelp, setShowUsernameHelp] = useState(false);

  const cleanSearchQuery = (query: string): string => {
    return query
      .replace(/https:\/\/(www\.)?tiktok\.com\//g, "")
      .replace(/@/g, "")
      .split("?")[0]
      .trim();
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();

    const cleanUsername = cleanSearchQuery(username);
    if (!cleanUsername) return;

    try {
      setIsSearching(true);
      await actions.searchTikTokUser(cleanUsername);
      navigate(`/tiktok-counter/${cleanUsername}`);
    } catch (error) {
      console.error("Error searching user:", error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanValue = cleanSearchQuery(value);
    setUsername(value);
  };

  const handleExampleClick = async (username: string) => {
    try {
      setIsSearching(true);
      await actions.searchTikTokUserCounter(username);
      navigate(`/tiktok-counter/${username}`);
    } catch (error) {
      console.error("Error searching user:", error);
    } finally {
      setIsSearching(false);
    }
  };

  const hasSpaces = username.includes(" ");

  return (
    <div className="flex min-h-screen flex-col items-center bg-[rgba(33,150,243,0.02)] px-4 py-4 pt-16">
      <div className="container max-w-6xl">
        {/* Header Section */}
        <div className="mb-12 sm:mb-16 text-center">
          <h1 className="mb-4 sm:mb-6 text-3xl sm:text-4xl font-bold">
            TikTok Live Followers Counter.
          </h1>
          <p className="text-lg sm:text-xl text-gray-600">
            Simply enter your TikTok username below to track your live follower
            count, updated in real-time with 100% accuracy.
          </p>
        </div>

        {/* Search Section */}
        <div className="mb-12 sm:mb-16">
          <form onSubmit={handleSearch} className="relative mx-auto max-w-3xl">
            <div className="absolute left-4 top-1/2 flex -translate-y-1/2 items-center gap-2">
              <svg
                width="32"
                height="32"
                viewBox="0 0 50 50"
                fill="black"
                className="!bg-black rounded-full"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  data-v-ee8a7686=""
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.6026 18.9307C32.7917 20.4943 35.4735 21.4141 38.3693 21.4141V17.0292C36.7526 16.6849 35.3224 15.8401 34.2459 14.6667C32.4042 13.5182 31.0776 11.6234 30.6891 9.40573H26.6292V31.6526C26.6193 34.2458 24.5141 36.3458 21.9167 36.3458C20.386 36.3458 19.0266 35.6167 18.1651 34.4875C16.6276 33.7125 15.5735 32.1193 15.5735 30.2807C15.5735 27.6792 17.6834 25.5708 20.286 25.5708C20.7849 25.5708 21.2651 25.6484 21.7167 25.7917V21.3609C16.1276 21.4766 11.6334 26.0401 11.6334 31.6542C11.6334 34.4568 12.7526 36.9974 14.5693 38.8526C16.2084 39.9526 18.1818 40.5943 20.3042 40.5943C25.9933 40.5943 30.6058 35.9849 30.6058 30.2984L30.6026 18.9307Z"
                  fill="#FFFFFF"
                ></path>{" "}
                <path
                  data-v-ee8a7686=""
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M38.3693 17.0307V15.8443C36.9109 15.8474 35.4833 15.4391 34.2458 14.6667C35.3401 15.8651 36.7818 16.6917 38.3693 17.0307ZM30.6875 9.40572C30.65 9.19322 30.6224 8.98072 30.6026 8.7651V8.04843H24.9974V30.2974C24.9891 32.8901 22.8818 34.9901 20.2849 34.9901C19.5224 34.9901 18.8026 34.8099 18.1651 34.4875C19.0266 35.6167 20.3859 36.3458 21.9167 36.3458C24.5141 36.3458 26.6193 34.2458 26.6292 31.6526V9.40572H30.6875ZM21.7151 21.3609V20.0984C21.2474 20.0349 20.775 20.0026 20.3016 20.0026C14.6109 20.0026 10 24.6125 10 30.2984C10 33.8641 11.8125 37.0057 14.5667 38.8526C12.75 36.9974 11.6307 34.4568 11.6307 31.6542C11.6307 26.0401 16.1266 21.4766 21.7151 21.3609Z"
                  fill="#FFFFFF"
                ></path>
              </svg>
              <span className="text-xl text-gray-400">@</span>
            </div>

            <Separator orientation="vertical" />
            <Input
              className={cn(
                "h-16 rounded-xl border pl-20 pr-16 text-lg",
                "placeholder:text-gray-400 focus-visible:ring-[rgb(33,150,243)]",
                hasSpaces && "border-red-300 focus-visible:ring-red-300"
              )}
              placeholder="Enter username or paste profile URL"
              value={username}
              onChange={handleInputChange}
              disabled={isSearching}
            />
            <button
              type="submit"
              disabled={isSearching || !username.trim()}
              className="absolute right-4 top-1/2 -translate-y-1/2 text-[rgb(33,150,243)] disabled:opacity-50"
            >
              {isSearching ? (
                <div className="h-6 w-6 animate-spin rounded-full border-2 border-[rgb(33,150,243)] border-t-transparent" />
              ) : (
                <Search className="h-6 w-6" />
              )}
            </button>
          </form>
          {hasSpaces && (
            <div className="mt-2 flex items-center justify-center gap-2 text-sm text-red-500">
              <Info className="h-4 w-4 text-red-300" />
              <span className="text-red-300">Username cannot contain spaces.</span>
              <button
                onClick={() => setShowUsernameHelp(true)}
                className="font-medium text-red-300 underline hover:no-underline"
              >
                How to find my username?
              </button>
            </div>
          )}
          <p className="mt-4 text-center text-gray-500">
            Give it a try:{" "}
            {EXAMPLE_USERS.map((user, index) => (
              <React.Fragment key={user.username}>
                <button
                  onClick={() => handleExampleClick(user.username)}
                  disabled={isSearching}
                  className={cn(
                    "text-[rgb(33,150,243)] hover:underline focus:outline-none disabled:opacity-50 disabled:no-underline",
                    isSearching && "cursor-not-allowed"
                  )}
                >
                  {user.displayName}
                </button>
                {index < EXAMPLE_USERS.length - 1 && ", "}
              </React.Fragment>
            ))}
          </p>
        </div>

        {/* Tools Section */}
        <div className="text-center">
          <h2 className="mb-8 sm:mb-12 text-2xl sm:text-3xl font-bold">More TikTok Free Tools</h2>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            <a 
              href="/get-free-likes"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <ToolCard
                icon={<div className="text-[rgb(33,150,243)] text-2xl">⚡</div>}
                title="Free TikTok Likes"
                isHot
              />
            </a>
            <a 
              href="/get-free-views"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <ToolCard
                icon={<div className="text-[rgb(33,150,243)] text-2xl">👁️</div>}
                title="Free TikTok Views"
                isHot
              />
            </a>
            <a 
              href="/get-free-shares"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <ToolCard
                icon={<div className="text-[rgb(33,150,243)] text-2xl">🔄</div>}
                title="Free TikTok Shares"
                isHot
              />
            </a>
          </div>
        </div>

        {/* About Section */}
        <div className="mt-16 sm:mt-24">
          <h2 className="text-center text-2xl sm:text-3xl font-bold">
            About TikTok Followers Counter
            <div className="mx-auto mt-2 h-1 w-[200px] sm:w-[300px] rounded-full bg-[rgb(33,150,243)]" />
          </h2>

          <div className="mt-8 sm:mt-12 flex flex-col gap-6 sm:gap-8">
            {/* FAQ Cards */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                What is live TikTok followers counter tool?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                The live TikTok followers counter is a tool that lets you track
                any account's follower count and likes in real time. It
                continuously updates, allowing you to see live changes as they
                happen. You can also view the counter in Fullscreen mode for a
                more immersive experience.
              </p>
            </div>

            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                Why not use the official TikTok counter?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                You can, but it lacks precision. For instance, if you have
                10,645 followers, TikTok will simply display '10k'.
                Additionally, you'll need to manually refresh to see updates.
                With Famepeak, our live counter updates your followers in
                real-time automatically—just keep the tab open.
              </p>
            </div>

            {/* Tools List Section */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                What other tools does Famepeak have?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                We have more free tools to help you grow your TikTok presence:
              </p>
              <ul className="mt-3 sm:mt-4 ml-4 sm:ml-6 list-disc text-sm sm:text-base text-gray-600">
                <li className="mt-2">
                  <a 
                    href="/get-free-likes"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[rgb(33,150,243)] hover:underline focus:outline-none"
                  >
                    TikTok Likes Generator
                  </a>
                </li>
                <li className="mt-2">
                  <a 
                    href="/get-free-views"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[rgb(33,150,243)] hover:underline focus:outline-none"
                  >
                    TikTok Views Generator
                  </a>
                </li>
                <li className="mt-2">
                  <a 
                    href="/get-free-shares"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[rgb(33,150,243)] hover:underline focus:outline-none"
                  >
                    TikTok Shares Generator
                  </a>
                </li>
              </ul>
            </div>

            {/* Apply same pattern to all other FAQ cards */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                What is TikTok?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                TikTok has gained a lot of popularity in recent months as an app
                to share and discover short video clips. It has the look of a
                simple video-sharing platform, but it's actually much more than
                that. When you first open the app, you see a feed of videos from
                people you follow and generally speaking, it feels similar to
                other popular video apps like Instagram or Snapchat. But then
                you click on your profile, swipe left and see all these
                different camera filters and options.
              </p>
              <p className="mt-4 text-gray-600">
                There's even an editing tab where users can add captions,
                stickers and other effects...all in addition to the filters.
                TikTok is a little different than other social media apps
                because it has two separate environments: the public feed for
                sharing videos with your followers and friends; and the private
                profile where you can edit your filter options, add sticker
                effects, insert captors and post videos privately to specific
                people instead of everyone who follows you.
              </p>
            </div>

            {/* How to use TikTok with Famepeak? */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                How to use TikTok with Famepeak?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                First off, let's talk about how to use Tik Tok. This app is
                simple, easy, and intuitive enough to be used by people of all
                ages and abilities. You can create your own video, or you can
                respond to a video that someone else has shared. You can also
                post short 15-second clips or use the music or special effects
                that TikTok has pre-loaded for you.
              </p>
              <p className="mt-4 text-gray-600">
                In addition to TikTok's tools, you can use third-party tools,
                such as a TikTok counter, to see your followers in real time.
              </p>
              <p className="mt-4 text-gray-600">
                If you want to take full advantage of everything we have to
                offer, in addition to the live TikTok counter, you'll want to
                use our analytics tool which can provide with a lot of data to
                use to analyze others and improve your performance on TikTok.
              </p>
            </div>

            {/* Is there any advantage to using the live TikTok followers counter? */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                Is there any advantage to using the live TikTok followers
                counter?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                A TikTok counter can be used by anyone, but not everyone needs
                one. TikTok's analytics are sufficient to keep track of your
                statistics if you do not post frequently or do not have a large
                number of followers. The app can still be used if you're curious
                about how much engagement your favorite creators are getting.
              </p>
              <p className="mt-4 text-gray-600">
                This tool may benefit marketers looking to place a product on a
                TikTok video as well, since it will show how many viewers they
                can reach.
              </p>
              <p className="mt-4 text-gray-600">
                Creators can use the data from Famepeak to expand their
                portfolios and make them more appealing to potential customers.
                When there is an expectation of a partnership, the trump card is
                to always provide up-to-date information.
              </p>
              <p className="mt-4 text-gray-600">
                TikTok Counters tools are useful for satisfying the curiosity of
                users, particularly those who are fans of social network
                personalities, in addition to content creators. This can be an
                interesting hobby if you want to keep up with For You Page
                celebrities.
              </p>
            </div>

            {/* How to check other TikTokers Followers count? */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                How to check other TikTokers Followers count?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                There are multiple ways to get this done. One method is to go to
                their profile page. Another option is to use a TikTok Counter,
                which allows you to see their follower count updates in real
                time, you'll only have to enter their username.
              </p>
            </div>

            {/* How do I benefit from this TikTok Counter as a content creator? */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">
                How do I benefit from this TikTok Counter as a content creator?
              </h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                If you're a TikTok content creator, having a large number of
                followers is a great accomplishment, but it's also a moment that
                you might miss if you don't record it, which also can be used as
                content.
              </p>
              <p className="mt-4 text-gray-600">
                TikTok counter can also be useful to track the growth of your
                followers in realtime, and see whether it is decreasing or
                increasing. Of course, you can also keep track of your progress.
              </p>
            </div>

            {/* Conclusion */}
            <div className="rounded-2xl border border-[rgb(33,150,243)]/20 bg-white p-6 sm:p-8">
              <h3 className="text-lg sm:text-xl font-bold">Conclusion</h3>
              <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600">
                Famepeak TikTok followers counter is completely safe for you to
                use and completely free. Most TikTokers are famous because they
                understand how to drive engagements; this is what this tool
                simplify for you; it provides you with reports and statistics to
                help you make the best decisions as a marketer looking to hire a
                TikToker, or as a TikToker looking to start a new trend.
              </p>
            </div>
          </div>
        </div>

        {/* Username Help Dialog */}
        <Dialog open={showUsernameHelp} onOpenChange={setShowUsernameHelp}>
          <DialogContent className="max-w-[327px] rounded-[12px]">
            <div className="p-0">
              <h2 className="text-[22px] font-semibold text-gray-900 mb-4">How to Find Your TikTok Username</h2>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <div className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                    <span className="text-purple-600 text-sm">1</span>
                  </div>
                  <p className="text-gray-600 text-sm">Open your TikTok profile</p>
                </div>
                <div className="flex items-start gap-3">
                  <div className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                    <span className="text-purple-600 text-sm">2</span>
                  </div>
                  <div className="flex-1">
                    <p className="text-gray-600 text-sm">Your username appears at the top of your profile with an @ symbol</p>
                    <img 
                      src="/profile.png" 
                      alt="TikTok Profile Username Location" 
                      className="mt-2 rounded-lg w-full object-cover border border-gray-100"
                    />
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                    <span className="text-purple-600 text-sm">3</span>
                  </div>
                  <p className="text-gray-600 text-sm">Enter your username without the @ symbol</p>
                </div>
              </div>
              <Button
                onClick={() => setShowUsernameHelp(false)}
                className="w-full mt-6"
              >
                Got it
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Footer />
      </div>
    </div>
  );
}
