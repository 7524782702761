import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import state from "@/store/state";
import actions from "@/store/actions";
import { Button } from "@/components/ui/button";
import { Maximize2, Minimize2, Copy, Check } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";

function SingleDigit({
  digit,
  direction,
}: {
  digit: string;
  direction: number;
}) {
  return (
    <motion.span
      initial={{ y: direction * 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: direction * -20, opacity: 0 }}
      transition={{ duration: 0.35, ease: "easeInOut" }}
    >
      {digit}
    </motion.span>
  );
}

function AnimatedNumber({
  value,
  previousValue,
}: {
  value: number;
  previousValue: number | null;
}) {
  const valueString = value.toLocaleString();
  const previousString = previousValue?.toLocaleString() || valueString;

  return (
    <div className="flex text-6xl font-bold text-gray-900">
      {valueString.split("").map((digit, index) => {
        const previousDigit = previousString[index] || digit;
        const direction = previousDigit
          ? parseInt(digit) > parseInt(previousDigit)
            ? 1
            : -1
          : 0;

        return (
          <AnimatePresence mode="wait" key={`${index}-${digit}`}>
            <SingleDigit digit={digit} direction={direction} />
          </AnimatePresence>
        );
      })}
    </div>
  );
}

function FollowerChangeText({
  currentCount,
  previousCount,
}: {
  currentCount: number;
  previousCount: number;
}) {
  if (currentCount === previousCount) return null;

  const isIncrease = currentCount > previousCount;
  const difference = Math.abs(currentCount - previousCount);

  return (
    <motion.div
      initial={{ opacity: 0, x: isIncrease ? 20 : -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: isIncrease ? -20 : 20 }}
      className={cn(
        "absolute -right-12 top-1/2 -translate-y-1/2 text-sm font-medium whitespace-nowrap",
        isIncrease ? "text-green-500" : "text-red-500"
      )}
    >
      {isIncrease ? "+" : "-"}
      {difference.toLocaleString()}
    </motion.div>
  );
}

export function TiktokCounterDetail() {
  const { username } = useParams();
  const navigate = useNavigate();
  const store = useSnapshot(state);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [followerCount, setFollowerCount] = useState<number | null>(null);
  const [previousCount, setPreviousCount] = useState<number | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isEmbedOpen, setIsEmbedOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // İlk yükleme
  useEffect(() => {
    const fetchUser = async () => {
      if (!username) return;

      try {
        setIsLoading(true);
        setError(null);
        await actions.searchTikTokUser(username);
        const initialCount = store.tiktokCounterResult?.totalFollower || 0;
        setFollowerCount(initialCount);
        setPreviousCount(initialCount);
      } catch (err) {
        setError("User not found");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [username]);

  // Her 5 saniyede bir güncelleme
  useEffect(() => {
    if (!username || error) return;

    const intervalId = setInterval(async () => {
      try {
        setIsUpdating(true);
        await actions.searchTikTokUserCounter(username);
        const newCount = store.tiktokCounterResult?.totalFollower || 0;

        // Sayı değiştiyse animasyonlu güncelleme
        if (newCount !== followerCount) {
          setPreviousCount(followerCount);
          setFollowerCount(newCount);
        }
      } catch (err) {
        console.error("Error updating follower count:", err);
      } finally {
        setIsUpdating(false);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [username, followerCount, error]);

  // Test için manuel follower değiştirme fonksiyonları
  const increaseFollowers = () => {
    if (followerCount === null) return;
    setPreviousCount(followerCount);
    setFollowerCount(followerCount + Math.floor(Math.random() * 100) + 1);
  };

  const decreaseFollowers = () => {
    if (followerCount === null) return;
    setPreviousCount(followerCount);
    setFollowerCount(
      Math.max(0, followerCount - (Math.floor(Math.random() * 100) + 1))
    );
  };

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      try {
        await containerRef.current?.requestFullscreen();
        setIsFullscreen(true);
      } catch (err) {
        console.error("Error attempting to enable fullscreen:", err);
      }
    } else {
      try {
        await document.exitFullscreen();
        setIsFullscreen(false);
      } catch (err) {
        console.error("Error attempting to exit fullscreen:", err);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const getEmbedCode = () => {
    const currentUrl = window.location.origin;
    return `<iframe
      src="${currentUrl}/tiktok-counter/${username}/embed"
      width="400"
      height="200"
      frameborder="0"
      scrolling="no"
      style="border: 1px solid #eee; border-radius: 12px;"
    ></iframe>`;
  };

  const handleCopyEmbed = () => {
    navigator.clipboard.writeText(getEmbedCode());
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-[rgba(33,150,243,0.02)]">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-[rgb(33,150,243)] border-t-transparent" />
      </div>
    );
  }

  if (error || !store.tiktokCounterResult) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center gap-4 bg-[rgba(33,150,243,0.02)] px-4 text-center">
        <h1 className="text-2xl font-bold text-gray-900">User Not Found</h1>
        <p className="text-gray-600">
          The TikTok user you're looking for doesn't exist or is private.
        </p>
        <Button
          onClick={() => navigate("/tiktok-counter")}
          className="mt-4 bg-[rgb(33,150,243)] hover:bg-[rgb(33,150,243)]/90"
        >
          Try Another Username
        </Button>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col items-center bg-[rgba(33,150,243,0.02)] px-4 py-8 sm:py-16">
      <div className="max-w-full">
        {/* Header Banner */}
        <div className="mb-6 sm:mb-12 rounded-xl bg-[rgb(255,45,85)] p-3 sm:p-4 text-white">
          <div className="flex items-center gap-2">
            <svg
              width="32"
              height="32"
              viewBox="0 0 50 50"
              fill="black"
              className="!bg-black rounded-full"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                data-v-ee8a7686=""
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.6026 18.9307C32.7917 20.4943 35.4735 21.4141 38.3693 21.4141V17.0292C36.7526 16.6849 35.3224 15.8401 34.2459 14.6667C32.4042 13.5182 31.0776 11.6234 30.6891 9.40573H26.6292V31.6526C26.6193 34.2458 24.5141 36.3458 21.9167 36.3458C20.386 36.3458 19.0266 35.6167 18.1651 34.4875C16.6276 33.7125 15.5735 32.1193 15.5735 30.2807C15.5735 27.6792 17.6834 25.5708 20.286 25.5708C20.7849 25.5708 21.2651 25.6484 21.7167 25.7917V21.3609C16.1276 21.4766 11.6334 26.0401 11.6334 31.6542C11.6334 34.4568 12.7526 36.9974 14.5693 38.8526C16.2084 39.9526 18.1818 40.5943 20.3042 40.5943C25.9933 40.5943 30.6058 35.9849 30.6058 30.2984L30.6026 18.9307Z"
                fill="#FFFFFF"
              ></path>{" "}
              <path
                data-v-ee8a7686=""
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.3693 17.0307V15.8443C36.9109 15.8474 35.4833 15.4391 34.2458 14.6667C35.3401 15.8651 36.7818 16.6917 38.3693 17.0307ZM30.6875 9.40572C30.65 9.19322 30.6224 8.98072 30.6026 8.7651V8.04843H24.9974V30.2974C24.9891 32.8901 22.8818 34.9901 20.2849 34.9901C19.5224 34.9901 18.8026 34.8099 18.1651 34.4875C19.0266 35.6167 20.3859 36.3458 21.9167 36.3458C24.5141 36.3458 26.6193 34.2458 26.6292 31.6526V9.40572H30.6875ZM21.7151 21.3609V20.0984C21.2474 20.0349 20.775 20.0026 20.3016 20.0026C14.6109 20.0026 10 24.6125 10 30.2984C10 33.8641 11.8125 37.0057 14.5667 38.8526C12.75 36.9974 11.6307 34.4568 11.6307 31.6542C11.6307 26.0401 16.1266 21.4766 21.7151 21.3609Z"
                fill="#FFFFFF"
              ></path>
            </svg>
            <span className="text-base sm:text-lg font-medium truncate">
              Live Followers Count: @{store.tiktokCounterResult.username}
            </span>
          </div>
        </div>

        {/* Profile Card */}
        <motion.div
          ref={containerRef}
          className={cn(
            "relative mb-6 sm:mb-12 overflow-hidden rounded-xl bg-white p-4 sm:p-8 shadow-sm transition-all",
            isFullscreen &&
              "fixed inset-0 m-0 flex flex-col items-center justify-center rounded-none"
          )}
        >
          <div
            className={cn(
              "mb-6 sm:mb-8 flex items-center gap-3 sm:gap-4",
              isFullscreen &&
                "scale-100 sm:scale-150 transition-transform mb-12 sm:mb-20"
            )}
          >
            <img
              src={store.tiktokCounterResult.profilePicture || "/default-avatar.png"}
              alt={store.tiktokCounterResult.username}
              className="h-16 w-16 sm:h-20 sm:w-20 rounded-full"
            />
            <div>
              <h2 className="text-xl sm:text-2xl font-bold truncate max-w-[200px] sm:max-w-none">
                {store.tiktokCounterResult.username}
              </h2>
              <p className="text-gray-600 text-sm sm:text-base truncate max-w-[200px] sm:max-w-none">
                {store.tiktokCounterResult.username}
              </p>
            </div>
          </div>

          <div
            className={cn(
              "mb-6 sm:mb-8 text-center",
              isFullscreen && "scale-100 sm:scale-150 transition-transform"
            )}
          >
            <h3 className="mb-2 text-base sm:text-lg font-medium text-gray-400">
              CURRENTLY HAS
            </h3>
            <div className="relative h-[60px] sm:h-[72px] flex justify-center items-center">
              <div className="relative">
                <AnimatePresence mode="wait">
                  {followerCount !== null && (
                    <div className="scale-75 sm:scale-100">
                      <AnimatedNumber
                        value={followerCount}
                        previousValue={previousCount}
                      />
                    </div>
                  )}
                </AnimatePresence>
                <AnimatePresence mode="wait">
                  {followerCount !== null &&
                    previousCount !== null &&
                    followerCount !== previousCount && (
                      <div className="scale-75 sm:scale-100">
                        <FollowerChangeText
                          currentCount={followerCount}
                          previousCount={previousCount}
                        />
                      </div>
                    )}
                </AnimatePresence>
              </div>
            </div>
            <div className="mt-2 text-base sm:text-lg font-medium text-gray-400">
              FOLLOWERS
            </div>

            {/* Test Butonları */}
            {import.meta.env.DEV && !isFullscreen && (
              <div className="mt-4 flex justify-center gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={decreaseFollowers}
                  className="text-xs sm:text-sm border-red-200 text-red-500 hover:bg-red-50"
                >
                  Random Decrease
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={increaseFollowers}
                  className="text-xs sm:text-sm border-green-200 text-green-500 hover:bg-green-50"
                >
                  Random Increase
                </Button>
              </div>
            )}
          </div>

          {!isFullscreen && (
            <div className="flex flex-col sm:flex-row justify-center gap-2 sm:gap-4">
              <Button
                className="flex items-center justify-center gap-2 bg-[rgb(255,45,85)] hover:bg-[rgb(255,45,85)]/90 text-sm sm:text-base"
                onClick={() => navigate(`/order?from=tiktok-counter&username=${username}`)}
              >
                Boost Your Followers Now →
                <span className="ml-1 rounded bg-white/20 px-2 py-0.5 text-xs">
                  New
                </span>
              </Button>
              <Button
                variant="outline"
                className="flex items-center justify-center gap-2 border-gray-200 text-sm sm:text-base"
                onClick={() => setIsEmbedOpen(true)}
              >
                Embed On Your Website <code>&lt;&gt;</code>
              </Button>
            </div>
          )}

          {/* Embed Dialog */}
          <Dialog open={isEmbedOpen} onOpenChange={setIsEmbedOpen}>
            <DialogContent className="sm:max-w-[700px]">
              <DialogHeader>
                <DialogTitle>Embed Counter</DialogTitle>
                <DialogDescription>
                  Copy and paste this code into your website to display the live
                  follower counter.
                </DialogDescription>
              </DialogHeader>
              <div className="relative mt-4">
                <pre className="rounded-lg bg-gray-50 p-4 text-sm font-mono overflow-x-auto whitespace-pre-wrap break-all">
                  {getEmbedCode()}
                </pre>
                <Button
                  size="sm"
                  onClick={handleCopyEmbed}
                  className="absolute right-2 top-2"
                >
                  {isCopied ? (
                    <>
                      <Check className="mr-2 h-4 w-4" />
                      Copied!
                    </>
                  ) : (
                    <>
                      <Copy className="mr-2 h-4 w-4" />
                      Copy Code
                    </>
                  )}
                </Button>
              </div>
              <div className="mt-4">
                <h4 className="text-sm font-medium mb-2">Preview:</h4>
                <div className="rounded-lg border p-4 bg-white">
                  <div className="flex items-center gap-3">
                    <img
                      src={store.tiktokCounterResult.profilePicture || "/default-avatar.png"}
                      alt={store.tiktokCounterResult.username}
                      className="h-10 w-10 rounded-full"
                    />
                    <div>
                      <div className="font-medium">@{store.tiktokCounterResult.username}</div>
                      <div className="text-sm text-gray-500">
                        {followerCount?.toLocaleString()} followers
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <button
            className="absolute right-3 top-3 sm:right-4 sm:top-4 rounded-lg border border-gray-100 bg-gray-50 p-1.5 sm:p-2 text-gray-400 hover:text-gray-600"
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <Minimize2 className="h-4 w-4 sm:h-5 sm:w-5" />
            ) : (
              <Maximize2 className="h-4 w-4 sm:h-5 sm:w-5" />
            )}
          </button>
        </motion.div>
      </div>
    </div>
  );
}
